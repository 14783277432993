<template>
  <v-row class="mt-1">
    <v-col cols="12">
      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field v-model="search" placeholder="Buscar" outlined hide-details dense class="user-search me-3 mb-4">
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn color="primary" class="mb-4 me-3" @click.stop="addItem">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Agregar Observación</span>
          </v-btn>
        </div>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="observations"
        :search="search"
        :footer-props="{
          'items-per-page-text': 'Observaciónes por página: ',
          'items-per-page-options': [15, 30, 50, 100],
        }"
        sort-by="name"
      >
        <template #[`item.date`]="{ item }">
          <div class="d-flex align-center">
            <span>{{ item.date ? date(item.date).format('DD/MM/YYYY HH:MM') : '' }}</span>
          </div>
        </template>
        <template #[`item.observation`]="{ item }">
          <div class="d-flex align-center">
            <span>{{
              item.observation.length > 50 ? item.observation.substring(0, 50) + '...' : item.observation
            }}</span>
          </div>
        </template>
        <template #[`item.user`]="{ item }">
          <div class="d-flex align-center">
            <span>{{ item.userInfo?.name }} {{ item.userInfo?.lastname }}</span>
          </div>
        </template>
        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <v-icon small class="me-2" @click="viewItem(item)">
            {{ icons.mdiEye }}
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} de {{ itemsLength }}
        </template>
        <template v-slot:no-data> No hay observaciónes para mostrar </template>
      </v-data-table>
    </v-col>

    <!-- dialog -->
    <v-dialog v-model="dialogs.add" :persistent="buttonLoading" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Observaciónes</span>
        </v-card-title>
        <v-form v-model="valid" ref="form" class="multi-col-validation" @submit.prevent="save">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="editedItem.observation"
                  :rules="[validators.required]"
                  label="Observación"
                  auto-grow
                ></v-textarea></v-col
            ></v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="close"> Cancelar </v-btn>
            <v-btn color="success" type="submit" :loading="buttonLoading" :disabled="buttonLoading"> Guardar </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.view" :persistent="buttonLoading" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Observaciónes</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="editedItem.observation"
                label="Observación"
                auto-grow
                rows="2"
              ></v-textarea></v-col
          ></v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="close"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { required } from '@core/utils/validation'
import { mdiDeleteOutline, mdiEye, mdiPlus } from '@mdi/js'
import { inject, ref } from 'vue'
import store from '@/store'
import snackbarPlugin from '@/plugins/snackbar'

export default {
  components: {},
  props: {
    value: {
      type: Array,
      default: [],
    },
    regular: {
      type: Object,
      default: {},
    },
  },
  setup(props, { emit }) {
    const observations = ref(JSON.parse(JSON.stringify(props.value)))
    observations.value =
      observations.value.length > 0 && Object.keys(observations.value[0]).length === 0 ? [] : observations.value

    const search = ref('')
    const buttonLoading = ref(false)
    const userData = store.getters['user/getUser']
    const date = inject('date')

    const headers = [
      { text: 'Fecha', value: 'date' },
      { text: 'Usuario', value: 'user' },
      { text: 'Observación', value: 'observation' },
      { text: 'Acciónes', value: 'actions' },
    ]

    const editedIndex = ref(-1)
    const editedItem = ref({})
    const defaultItem = {
      user: '',
      observation: '',
    }

    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const save = async () => {
      try {
        buttonLoading.value = true
        if (valid.value) {
          editedItem.value.date = date().toISOString()
          editedItem.value.user = userData._id
          if (!props.regular._id) {
            if (editedIndex.value > -1) {
              Object.assign(observations.value[editedIndex.value], editedItem.value)
            } else {
              observations.value.push(editedItem.value)
            }
          } else {
            const response = await store.dispatch('app-regular/addObservation', {
              regular: props.regular,
              observation: editedItem.value,
            })
            observations.value = response.data.regular.observations
          }
          emit('input', observations.value)
          buttonLoading.value = false
          close()
          snackbarPlugin.showMessage({ content: 'Guardado Correctamente', color: 'success', timeout: 10000 })
        } else {
          buttonLoading.value = false
          validate()
        }
      } catch (error) {
        console.log(error)
        snackbarPlugin.showMessage({
          content: 'Ocurrio un error, intentelo nuevamente',
          color: 'error',
          timeout: 10000,
        })
      }
    }

    const deleteItem = async item => {
      const didConfirm = await store.dispatch('alertConfirm/CONFIRM', {
        title: '¿Esta seguro que desea borrarlo?',
        message: 'No podra recuperarlo',
      })
      if (didConfirm) {
        if (props.regular._id) {
          const response = await store.dispatch('app-regular/deleteObservation', {
            regular: props.regular,
            observation: item,
          })
          if (response.status == 200) {
            observations.value = response.data.regular.observations
            emit('input', observations.value)
            snackbarPlugin.showMessage({ content: 'Borrado Correctamente', color: 'success', timeout: 10000 })
          } else {
            console.log(response)
            snackbarPlugin.showMessage({
              content: 'Ocurrio un error, intentelo nuevamente',
              color: 'error',
              timeout: 10000,
            })
          }
        } else {
          observations.value.splice(observations.value.indexOf(item), 1)
        }
      }
    }

    const addItem = () => {
      dialogs.value.add = true
      editedItem.value = { ...defaultItem }
      editedIndex.value = -1
    }

    const viewItem = item => {
      editedItem.value = {...item}
      dialogs.value.view = true
    }

    const close = () => {
      if (!buttonLoading.value) {
        editedItem.value = { ...defaultItem }
        editedIndex.value = -1
        dialogs.value.view = false
        dialogs.value.add = false
      }
    }

    const dialogs = ref({
      add: false,
      view: false,
      delete: false,
    })

    const valid = ref(false)

    return {
      viewItem,
      deleteItem,
      observations,
      headers,
      editedIndex,
      addItem,
      close,
      editedItem,
      search,
      defaultItem,
      buttonLoading,
      dialogs,
      valid,
      save,
      form,
      date,

      // icons
      icons: {
        mdiDeleteOutline,
        mdiEye,
        mdiPlus,
      },

      validators: { required },
    }
  },
}
</script>

<style></style>
