<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="computedDateFormatted"
        :label="label"
        persistent-hint
        :prepend-icon="icons.mdiCalendar"
        :rules="rules"
        readonly
        v-bind="attrs"
        v-on="on"
        :outlined="$props.outlined"
        :dense="$props.dense"
      ></v-text-field>
    </template>

    <v-date-picker v-model="date" locale="es" no-title color="primary" @input="sendDate"></v-date-picker>
  </v-menu>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { computed, ref, watch } from 'vue'
import { mdiCalendar } from '@mdi/js'
import { required } from '@core/utils/validation'

export default {
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    rules: {
      type: Array,
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
  },
  setup(props, { emit }) {
    const date = ref(props.value)
    const menu = ref(false)

    const formatDate = dates => {
      if (!dates) return null
      const [year, month, day] = dates.split('-')

      return `${day}/${month}/${year}`
    }

    const computedDateFormatted = computed(() => formatDate(date.value))

    const sendDate = item => {
      emit('input', date)
      menu.value = false
    }

    watch(
      () => props.value,
      value => {
        date.value = value
      },
    )

    return {
      date,
      menu,
      computedDateFormatted,
      formatDate,
      sendDate,
      validators: { required },

      // icons
      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>
