<template>
  <v-autocomplete
    v-model="users"
    :items="$props.usersList"
    filled
    chips
    :label="label"
    item-text="name"
    item-value="_id"
    @input="sendUser"
    :menu-props="{ contentClass: 'list-style' }"
    :search-input.sync="searchInput"
    :multiple="multiple"
    :rules="rules"
    :filter="customFilter"
    :outlined="$props.outlined"
    :dense="$props.dense"
    :loading="$props.loading"
    no-data-text="No hay resultados"
  >
    <template #selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select, openUser(data.item)"
        @click:close="remove(data.item)"
      >
        <v-avatar
          :color="data.item.avatar ? '' : 'primary'"
          :class="data.item.avatar ? '' : 'v-avatar-light-bg primary--text'"
          size="32"
          left
        >
          <v-img
            v-if="data.item.avatar"
            :src="$store.state.apidomain + '/user/avatars/' + data.item.avatar + '?rand=' + rand"
          ></v-img>
          <span v-else class="font-weight-medium">{{ avatarText(data.item.name + ' ' + data.item.lastname) }}</span>
        </v-avatar>
        {{ data.item.name }} {{ data.item.lastname }}
      </v-chip>
    </template>

    <template #item="data">
      <template>
        <v-list-item-avatar>
          <v-img
            v-if="data.item.avatar"
            :src="$store.state.apidomain + '/user/avatars/' + data.item.avatar + '?rand=' + rand"
          ></v-img>
          <span v-else class="font-weight-medium">{{ avatarText(data.item.name + ' ' + data.item.lastname) }}</span>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title> {{ data.item.name }} {{ data.item.lastname }} </v-list-item-title>
          <v-list-item-subtitle>
            {{ data.item.document }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import { onUnmounted, ref, watch } from 'vue'

export default {
  props: {
    usersList: {
      type: Array
    },
    value: {
    },
    label: {
      type: String,
      required: true,
    },
    multiple: {
      default: false,
      type: Boolean,
    },
    rules: {
      type: Array,
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {

    const users = ref(props.value ? props.value : [])
    const rand = ref(Date.now())
    const searchInput = ref('')

    const remove = item => {
      if (props.multiple) {
        const index = users.value.indexOf(item._id)
        if (index >= 0) users.value.splice(index, 1)
      } else {
        users.value = []
      }
      emit('input', users.value)
    }

    const sendUser = () => {
      searchInput.value = ''
      emit('input', users.value)
    }

    const customFilter = (item, queryText, itemText) => {
      const document = item.document
      const name = item.name.toLowerCase()
      const lastname = item.lastname.toLowerCase()
      const searchText = queryText.toLowerCase()

      return document.indexOf(searchText) > -1 || name.indexOf(searchText) > -1 || lastname.indexOf(searchText) > -1
    }

    const openUser = user => {
      window.open(`/apps/user/view/${user._id}`, '_blank')
    }
    
    watch(
      () => props.value,
      value => {
        users.value = value
      },
    )

    watch(
      () => props.usersList,
      value => {
        users.value = props.value
      },
    )


    return {
      searchInput,
      users,
      sendUser,
      remove,
      avatarText,
      customFilter,
      rand,
      openUser,
    }
  },
}
</script>
